<template>
  <div class="col-md-12 box">
    <div class="row remote_video_container">
      <div id="remoteTrack"></div>
    </div>
    <div class="spacing"></div>
    <div class="row">
      <div id="localTrack"></div>
    </div>
    <div class="roomTitle">
      <span v-if="loaded"> {{ $t('labels.connected_to') }} {{ roomName | VMask('###-###-####') }}</span>
      <span v-else> {{ $t('labels.loading_room') }} {{ roomName | VMask('###-###-####') }}</span>
    </div>
  </div>
</template>

<script>
import Twilio from 'twilio-video';
// import axios from 'axios';

export default {
  data: () => ({
    loaded: false,
    localTrack: false,
    remoteTrack: '',
    activeRoom: '',
    identity: '',
    alert: {
      type: 'success',
      text: '',
    },
  }),
  props: ['roomName', 'user', 'participantSid', 'leave', 'join', 'muted'],
  created() {
    this.getAccessToken();

    // When a user is about to transition away from this page,
    // disconnect from the room, if joined.
    // window.addEventListener('beforeunload', this.leaveRoomIfJoined);
  },
  methods: {
    async getAccessToken() {
      await this.$store.dispatch('videos/getTwilioAccessToken', {
        payload: {
          user: `CGV_${this.user}.${Math.random().toString(36)}`,
          phonenumber: this.roomName,
        },
      });
      if (this.$store.state.videos.accessToken) {
        this.joinRoom(this.roomName);
      } else {
        this.alert.type = 'error';
        this.alert.text = `Failed to connect: ${this.roomName}`;
        this.$emit('disconnected', this.alert);
      }
    },
    // Attach the Tracks to the DOM.
    attachTracks(tracks, container) {
      tracks.forEach((track) => {
        if (track.kind !== 'data') {
          const el = track.attach();
          if (track.kind === 'video') {
            el.setAttribute('controls', 'controls');
          }
          container.appendChild(el);
        }
      });
    },
    // Detach the Tracks from the DOM.
    detachTracks(tracks) {
      tracks.forEach((track) => {
        if (track.kind !== 'data') {
          track.detach().forEach((detachedElement) => {
            detachedElement.remove();
          });
        }
      });
    },
    // Leave Room.
    leaveRoomIfJoined() {
      if (this.activeRoom) {
        this.activeRoom.disconnect();
        this.loaded = false;
        this.activeRoom = '';
        this.localTrack = false;
        this.remoteTrack = '';
      }
      this.$emit('loaded', false);
    },
    toggleAudio(bool) {
      if (bool) {
        this.activeRoom.localParticipant.audioTracks.forEach((publication) => {
          publication.track.disable();
        });
      } else {
        this.activeRoom.localParticipant.audioTracks.forEach((publication) => {
          publication.track.enable();
        });
      }
    },
    unpublishLocalVideo(bool) {
      if (bool) {
        this.activeRoom.localParticipant.videoTracks.forEach((publication) => {
          publication.unpublish();
          publication.track.stop();
        });
      }
    },
    async joinRoom(roomName) {
      const token = this.$store.state.videos.accessToken;
      const connectOptions = {
        name: roomName,
        audio: true,
        video: false,
      };
      // before a user enters a new room,
      // disconnect the user from they joined already
      // this.leaveRoomIfJoined();

      try {
        const room = await Twilio.connect(token, connectOptions);

        this.activeRoom = room;
        this.loaded = true;

        this.$emit('loaded', true);

        this.unpublishLocalVideo(true);

        // Attach the Tracks of the Room's Participants.
        room.participants.forEach((participant) => {
          const previewContainer = document.getElementById('remoteTrack');
          participant.on('trackSubscribed', (track) => this.attachTracks([track], previewContainer));
          participant.on('trackUnsubscribed', (track) => this.detachTracks([track]));
        });

        // When a Participant joins the Room, log the event.
        room.on('participantConnected', (participant) => {
          const previewContainer = document.getElementById('remoteTrack');
          participant.on('trackSubscribed', (track) => this.attachTracks([track], previewContainer));
          participant.on('trackUnsubscribed', (track) => this.detachTracks([track]));
        });

        // When a Participant adds a Track, attach it to the DOM.
        room.on('trackAdded', (track) => {
          const previewContainer = document.getElementById('remoteTrack');
          this.attachTracks([track], previewContainer);
        });

        // When a Participant removes a Track, detach it from the DOM.
        room.on('trackRemoved', (track) => {
          this.detachTracks([track]);
        });

        // When a Participant leaves the Room, detach its Tracks.
        room.on('participantDisconnected', (participant) => {
          if (!participant.identity.includes('CGV')
            && !participant.identity.includes('112.video')) {
            this.$emit('leaveCall');
          }
          if (participant.sid === this.participantSid) {
            this.alert.type = 'warning';
            this.alert.text = `Mobile participant left: ${this.roomName}`;
            this.leaveRoomIfJoined();
            this.$emit('disconnected', this.alert);
          }
        });
      } catch (error) {
        this.alert.type = 'error';
        this.alert.text = `Failed to connect: ${this.roomName}`;
        this.$emit('disconnected', this.alert);
      }
    },
  },
  watch: {
    leave(bool) {
      if (bool) {
        this.leaveRoomIfJoined();
      }
    },
    join(bool) {
      if (bool) {
        this.getAccessToken();
      }
    },
    muted(bool) {
      this.toggleAudio(bool);
    },
  },
};
</script>

<style>
.remote_video_container {
  left: 0;
  margin: 0;
}
#remoteTrack video {
  min-height: 300px;
  height: 100%;
  width: 100%;
}
#localTrack video {
  margin-left: 15px;
  height: 90% !important;
  width: 100%;
  background-repeat: no-repeat;
}
.spacing {
  padding: 20px;
  width: 100%;
}
.roomTitle {
  text-align: center;
  padding: 4px;
  color: rgb(24, 173, 74);
}
</style>
