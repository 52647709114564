<template>
  <div>
    <div class="maps-container elevation-1 rounded">
      <GmapMap
        ref="mapRef"
        :center="center"
        :zoom="zoom"
        :options="options"
        style="height: 310px">
        <GmapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :icon="m.type === 'organization'
            ? { url: require('../assets/org_mark.png') }
            : { url: require('../assets/streaming_mark.png') }"
          @click="center = m.position"
        />
      </GmapMap>
    </div>
    <div v-if="markers.length > 0" class="my-2 text-center">
      <div v-for="(marker, index) in markers" :key="index">
        <CopyCoordinates
          :lat="marker.position.lat"
          :lng="marker.position.lng"
          :position-type="marker.type"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CopyCoordinates from './UI/CopyCoordinates.vue';

export default {
  name: 'GoogleMap',
  data: () => ({
    places: [],
    options: {
      streetViewControl: false,
      rotateControl: false,
      mapTypeControl: true,
      fullscreenControl: false,
    },
  }),
  components: {
    CopyCoordinates,
  },
  computed: {
    markers() {
      const activeStreamMarker = this.$store.getters['videos/getActiveStreamMarkers'];
      if (activeStreamMarker.length > 0) {
        return activeStreamMarker;
      }
      if (this.$store.state.organizations.userOrganization.latitude
          && this.$store.state.organizations.userOrganization.longitude) {
        return [{
          position: {
            lat: parseFloat(this.$store.state.organizations.userOrganization.latitude),
            lng: parseFloat(this.$store.state.organizations.userOrganization.longitude),
          },
          type: 'organization',
        }];
      }
      return [];
    },
    center() {
      if (this.markers.length) {
        return this.markers[this.markers.length - 1].position;
      }

      return { lat: 45.4985, lng: -73.5794 };
    },
    zoom() {
      if (this.$store.state.organizations.userOrganization) {
        return this.$store.state.organizations.userOrganization.zoom || 12;
      }

      return 8;
    },
  },
  methods: {
    showAllMarks() {
      const b = new google.maps.LatLngBounds(); //eslint-disable-line
      this.markers.forEach((item) => {
        b.extend(item.position);
      });
      this.$refs.mapRef.fitBounds(b);
    },
  },
  watch: {
    markers(val) {
      if (val.length > 1) {
        setTimeout(() => { this.showAllMarks(); }, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.maps-container {
  overflow: hidden;
  height: 310px;
}
</style>
