<template>
  <tr @click="onRowClick(item)" :class="{ 'is-live': item.isLive }">
    <td>
      {{ item.deviceName }}
    </td>
    <td>
      {{ item.deviceType }}
    </td>
    <td>
      {{ item.user }}
    </td>
    <td>
      {{ (item.phoneNumber || item.twilioData.RoomName) | VMask('###-###-####') }}
    </td>
    <td>
      {{ item.referenceNumber || '' }}
    </td>
    <td class="text-center">
      <v-chip
        :color="item.isLive ? 'secondary' : 'primary'"
        outlined
        small
        class="isLiveChip white--text font-weight-medium"
        v-if="item.sourceType === 'RTMP'"
      >
        {{ item.isLive ? $t('labels.active') : $t('labels.inactive') }}
      </v-chip>
      <v-chip
        :color="item.isLive ? 'secondary' : 'primary'"
        outlined
        small
        class="isLiveChip white--text font-weight-medium"
        v-else
      >
        {{ item.isLive ? $t('labels.joined') : $t('labels.join') }}
      </v-chip>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => null,
    },
  },
  methods: {
    onRowClick(value) {
      this.$store.commit('videos/TOGGLE_VIDEO', value);
      // this.$store.commit('videos/TOGGLE_VIDEO_CONF', value);
      if (value.streamType !== 'RTMP') {
        this.$emit('onJoinRoom', value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
tr {
  cursor: pointer;
  td {
    letter-spacing: 0.4px;
  }
  .isLiveChip {
    min-width: 72px;
    justify-content: center;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.1px;
  }
}
.theme--light tr {
  &.is-live {
    background-color: #F3F5F8;
  }
  td {
    color: #05172e;
  }
}
.theme--dark tr {
  &.is-live {
    background-color: #616161;
  }
}
</style>
