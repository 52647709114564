<template>
  <div class="pa-4">
    <v-row>
      <v-col cols="4 stream-map">
        <GoogleMaps />
      </v-col>
      <v-col>
        <Table @onJoinRoom="openVideoConference" />
        <v-dialog v-model="showVideoConference" width="700" :persistent="true">
          <v-card min-height="500px" class="d-flex justify-center align-center flex-column">
            <div v-show="conferenceLoaded">
              <VideoWebRTC
                :roomName="phoneNumber"
                :user="user"
                :participantSid="participantSid"
                :join="showVideoConference"
                :leave="!showVideoConference"
                :muted="isMuted"
                @leaveCall="leaveCall"
                @loaded="(ev) => conferenceLoaded = ev"
                @disconnected="closeVideoConference"
              />
              <v-card-text class="text--primary px-0">
                <div class="text-center">
                  <v-btn @click="closeVideoConference" class="mx-2" fab color="red" dark>
                    <v-icon dark>{{ hangUpIcon }}</v-icon>
                  </v-btn>
                  <v-btn v-if="!isMuted" class="mx-2" fab color="green" dark @click="isMuted = true">
                    <v-icon dark>{{ unMuteIcon }}</v-icon>
                  </v-btn>
                  <v-btn v-else class="mx-2" fab color="gray" dark @click="isMuted = false">
                    <v-icon dark>{{ muteIcon }}</v-icon>
                  </v-btn>
                  <v-btn class="mx-2" fab color="gray" dark @click="setVideoFullscreen">
                    <v-icon dark>{{ fullscreenIcon }}</v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </div>
            <div v-if="conferenceLoaded === false" class="text-center">
              <p>
                {{ $t('labels.connecting_to') }} {{ phoneNumber | VMask('###-###-####') }}
              </p>
              <v-progress-circular indeterminate size="60" />
            </div>
          </v-card>
        </v-dialog>
        <v-alert v-if="alert.text" :type="alert.type" class="mt-4" border="left" text dense dismissible>
          {{ alert.text }}
        </v-alert>
      </v-col>
    </v-row>
    <v-divider class="my-4" />
    <v-row class="videos-container" v-if="!showVideoConference">
      <v-col v-for="video in videos" :key="video.id" cols="3">
        <VideoPlayer :playerId="`player_${video.id}`" :streamUrl="video.streamUrl" />
      </v-col>
    </v-row>
    <AudioNotification :sources="[audioSources]" :notify="isNewStream" @notified="resetNewStream" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  mdiPhoneHangup, mdiMicrophoneOff, mdiMicrophone, mdiFullscreen,
} from '@mdi/js';
import GoogleMaps from '@/components/GoogleMaps';
import Table from '@/components/Streams/Table';
import VideoPlayer from '@/components/VideoPlayer';
import VideoWebRTC from '../components/VideoWebRTC';
import AudioNotification from '../components/AudioNotification';
import AudioEN from '../assets/mp3/notification-en.mp3';
import AudioFR from '../assets/mp3/notification-fr.mp3';

export default {
  name: 'Home',
  data: () => ({
    showVideoConference: false,
    hangUpIcon: mdiPhoneHangup,
    muteIcon: mdiMicrophoneOff,
    unMuteIcon: mdiMicrophone,
    fullscreenIcon: mdiFullscreen,
    isMuted: false,
    phoneNumber: '',
    user: '',
    participantSid: '',
    videoConference: false,
    conferenceLoaded: false,
    alert: {
      type: 'success',
      text: '',
    },
    audioSources: AudioFR,

  }),
  components: {
    GoogleMaps,
    Table,
    VideoPlayer,
    VideoWebRTC,
    AudioNotification,
  },
  mounted() {
    if (!this.$store.state.videos.isInitialized && !this.$store.state.auth.claims?.superAdmin
      && !this.$store.state.auth.claims?.eyeStaff) {
      this.$store.dispatch('videos/getActiveStreams');
    }

    if (!this.$store.state.users.isInitialized) {
      this.$store.dispatch('users/getUsers');
    }

    this.$store.dispatch('videos/getActiveStreams', localStorage.getItem('organization'));
    // detects browser language and defaults to FR if language is not EN
    const userLang = navigator.language || navigator.userLanguage || '';
    if (userLang.includes('en')) {
      this.audioSources = AudioEN;
    }
  },
  computed: {
    ...mapState('organizations',
      {
        selectedOrg: 'selectedOrganization',
      }),
    videos() {
      return this.$store.state.videos.displayedVideos;
    },
    isNewStream() {
      if (!this.showVideoConference) {
        return this.$store.state.videos.isNewStream;
      }

      if (this.$store.state.userPreferences.autoPlay && !this.conferenceLoaded) {
        return this.$store.state.videos.isNewStream;
      }

      this.resetNewStream();
      return false;
    },
  },
  methods: {
    openVideoConference(payload) {
      this.phoneNumber = payload.id;
      this.user = `web ${this.$store.state.auth.username}`;
      this.participantSid = payload.twilioData.ParticipantSid;
      this.videoConference = payload;
      this.showVideoConference = true;
    },
    closeVideoConference(alert) {
      this.alert = alert;
      this.$store.commit('videos/TOGGLE_VIDEO', this.videoConference);
      this.showVideoConference = false;
    },
    resetNewStream() {
      this.$store.commit('videos/RESET_NEW_STREAM', false);
    },
    leaveCall() {
      this.exitFullscreen();
      this.showVideoConference = false;
    },
    exitFullscreen() {
      if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
      // Exit fullscreen mode
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    },
    setVideoFullscreen() {
      const { userAgent } = navigator;
      const videoContainer = document.getElementById('remoteTrack');
      if (userAgent.indexOf('Chrome') !== -1) {
        // Chrome
        videoContainer.requestFullscreen();
      } else if (userAgent.indexOf('Firefox') !== -1) {
        // Firefox
        videoContainer.mozRequestFullScreen();
      } else if (userAgent.indexOf('Safari') !== -1) {
        // Safari
        videoContainer.webkitRequestFullscreen();
      } else if (userAgent.indexOf('Edge') !== -1) {
        // Microsoft Edge (Chromium-based)
        videoContainer.msRequestFullscreen();
      } else {
        videoContainer.requestFullscreen();
      }
    },
  },
  watch: {
    videos: {
      handler(stream) {
        if (stream.length > 0) {
          const webRtcVideoIndex = stream.findIndex((s) => s.streamType === 'WebRTC');
          if (webRtcVideoIndex !== -1) {
            this.openVideoConference(stream[webRtcVideoIndex]);
          }
        }
      },
      flush: 'post',
    },
    selectedOrg(newVal) {
      this.$store.dispatch('videos/getActiveStreams', newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.videos-container {
  height: calc(100vh - 459px + 28px);
  overflow-y: auto;
}
.v-dialog__content {
  justify-content: flex-end;
  position: absolute;
}

.stream-map {
  z-index: 201;
}
</style>
