<template>
  <div class="video-container">
      <div class="spinner" v-if="isLoading">
      <v-progress-circular v-if="!hasError" :size="70" :width="7" color="white" indeterminate />
      <div v-else class="text-center">
        <v-icon x-large color="white">{{ errorIcon }}</v-icon>
        <p class="white--text mt-3">{{ $t('error.play_video') }}</p>
      </div>
    </div>
    <canvas :id="playerId" v-show="!hasError" class="rounded" @click="onFullScreen"/>
  </div>
</template>

<script>
import { mdiClose, mdiAlert } from '@mdi/js';

export default {
  props: {
    playerId: {
      type: String,
      default: () => 'playerId',
    },
    streamUrl: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    player: null,
    fullPlayer: null,
    isLoading: true,
    isFullPlayerLoading: true,
    isVideoOpen: true,
    hasError: false,
    closeIcon: mdiClose,
    errorIcon: mdiAlert,
  }),
  mounted() {
    NodePlayer.load(() => { //eslint-disable-line
      this.player = new NodePlayer(); //eslint-disable-line
      this.player.setView(this.playerId);
      this.player.start(this.streamUrl);
      this.isLoading = false;
    });
  },
  methods: {
    onFullScreen() {
      this.player.fullscreen();
    },
  },
  computed: {
    maxWidth() {
      return `${window.innerWidth}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
canvas {
  margin: 0 auto;
  height: calc(calc(100vh - 494px) / 2) !important;
  width: 100%;
  background-color: black;
}

.video-container {
  position: relative;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
