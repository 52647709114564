<template>
  <div v-if="false"></div>
</template>

<script>
import VueHowler from 'vue-howler';

export default {
  name: 'AudioNotification',
  mixins: [VueHowler],
  props: {
    notify: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    notify(val) {
      if (val) {
        this.togglePlayback();
      }
    },
    playing(val) {
      if (!val) {
        this.$emit('notified');
      }
    },
  },
};
</script>
