<template>
  <v-data-table
    :headers=" [
      { class: 'caption darkblue--text', text: $t('labels.device') },
      { class: 'caption darkblue--text', text: $t('labels.type') },
      { class: 'caption darkblue--text', text: $t('labels.user') },
      { class: 'caption darkblue--text', text: $t('labels.phone') },
      { class: 'caption darkblue--text', text: $t('labels.reference_number') },
      { class: 'caption darkblue--text', text: '' },
    ]"
    :items="items"
    disableSort
    hideDefaultFooter
    class="elevation-1 table"
  >
    <template v-slot:item="{ item }">
      <TableRow :item="item" @onJoinRoom="$emit('onJoinRoom', item)"/>
    </template>
  </v-data-table>
</template>

<script>
import TableRow from '@/components/Streams/TableRow';

export default {
  // data: () => ({
  //   headers: [
  //     { class: 'caption darkblue--text', text: $t('labels.device') },
  //     { class: 'caption darkblue--text', text: 'Type' },
  //     { class: 'caption darkblue--text', text: 'Utilisateur' },
  //     { class: 'caption darkblue--text', text: 'Téléphone' },
  //     { class: 'caption darkblue--text', text: '' },
  //   ],
  // }),
  computed: {
    items() {
      const users = this.$store.state.users.users || [];
      const streams = this.$store.getters['videos/getStreams'];
      return streams.map((s) => {
        const user = users.find((u) => u.id === s.userId);
        return { ...s, referenceNumber: user.referenceNumber || '' };
      });
    },
  },
  components: {
    TableRow,
  },
};
</script>

<style lang="scss" scoped>
.table {
  height: 310px;
  overflow-y: auto;
}
</style>
