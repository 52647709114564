var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 table",attrs:{"headers":[
    { class: 'caption darkblue--text', text: _vm.$t('labels.device') },
    { class: 'caption darkblue--text', text: _vm.$t('labels.type') },
    { class: 'caption darkblue--text', text: _vm.$t('labels.user') },
    { class: 'caption darkblue--text', text: _vm.$t('labels.phone') },
    { class: 'caption darkblue--text', text: _vm.$t('labels.reference_number') },
    { class: 'caption darkblue--text', text: '' } ],"items":_vm.items,"disableSort":"","hideDefaultFooter":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
  var item = ref.item;
return [_c('TableRow',{attrs:{"item":item},on:{"onJoinRoom":function($event){return _vm.$emit('onJoinRoom', item)}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }